.card {
  transition: all 0.5s ease 0s;
  border-radius: 10px !important;
  /* padding: 1.5rem !important; */
  background: transparent;
  margin: 0 24px;
  width: 318px;
  transition: 0.5s;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  transition-delay: 0s;
}

.cardcontent {
  padding: 1.5rem !important;
}

.card:hover {
  background: #fff9f4 !important;
  /* box-shadow: 6px 12px 40px 0px rgba(174, 174, 174, 0.75); */
  box-shadow: 0px 30px 100px #0000001f;
}

.card-heading {
  font-family: "sans-bold", sans-serif;
  font-weight: 700;
  font-size: 18px;
}

.card-text {
  color: #000;
  opacity: 0.6;
  transition: 0.5s;
  margin-bottom: 20px;
}

/* .fakethumb {
  height: 200px;
  width: 100%;
  background: url("./service3.png");
  border-radius: 0.99em;
  object-fit: cover;
  transition: transform;
} */

.fakethumb:hover {
  transform: scale(1.1, 1.1) translateY(10px);
}

.thumb1 {
  background-color: #3d349d;
}
.thumb2 {
  background-color: #ffb039;
}
.thumb3 {
  background-color: #ff3d53;
}

.btn-third {
  /* box-shadow: none !important; */
  background-color: #3d349d;
  font-weight: 700;
  border-radius: 5px;
  padding: 20px 40px !important;
  font-size: 14px !important;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  line-height: 1.5;
  position: relative;
  width: 100%;
  background-color: transparent;
  color: #3d349d !important;
  border-color: #3d349d;
  padding-right: 15px;
  padding-left: 15px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 8px;
}

.btn-third > a {
  color: #3d349d;
  text-decoration: none;
}

.btn-third:hover {
  background: #3d349d;
  box-shadow: 6px 12px 40px 0px#3d349d;
}
.btn-third:hover > a {
  color: #fff;
}
/* dfsdfsdf */
.btn-fourth {
  /* box-shadow: none !important; */
  background-color: #4e9d34;
  font-weight: 700;
  border-radius: 5px;
  padding: 20px 40px !important;
  font-size: 14px !important;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  line-height: 1.5;
  position: relative;
  width: 100%;
  background-color: transparent;
  color: #4e9d34 !important;
  border-color: #4e9d34;
  padding-right: 15px;
  padding-left: 15px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 8px;
}

.btn-fourth > a {
  color: #4e9d34;
  text-decoration: none;
}

.btn-fourth:hover {
  background: #4e9d34;
  box-shadow: 6px 12px 40px 0px#4e9d34;
}
.btn-fourth:hover > a {
  color: #fff;
}

/* sdfsdfsf */ /* dfsdfsdf */
.btn-fifth {
  /* box-shadow: none !important; */
  background-color: #074278;
  font-weight: 700;
  border-radius: 5px;
  padding: 20px 40px !important;
  font-size: 14px !important;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  line-height: 1.5;
  position: relative;
  width: 100%;
  background-color: transparent;
  color: #074278 !important;
  border-color: #074278;
  padding-right: 15px;
  padding-left: 15px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 8px;
}

.btn-fifth > a {
  color: #074278;
  text-decoration: none;
}

.btn-fifth:hover {
  background: #074278;
  box-shadow: 6px 12px 40px 0px#074278;
}
.btn-fifth:hover > a {
  color: #fff;
}

/* sdfsdfsf */
.btn-third-sec {
  /* box-shadow: none !important; */
  background-color: #3d349d;
  font-weight: 700;
  border-radius: 5px;
  padding: 20px 40px !important;
  font-size: 14px !important;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  line-height: 1.5;
  position: relative;
  width: 100%;
  background-color: #3d349d;
  color: #fff !important;
  border-color: #3d349d;
  padding-right: 15px;
  padding-left: 15px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 8px;
  text-decoration: none;
}

.btn-third-sec > a {
  color: #3d349d;
  text-decoration: none;
}

.btn-third-sec:hover {
  background: #3d349d;
  box-shadow: 6px 12px 40px 0px#3d349d;
}
.btn-third-sec:hover > a {
  color: #fff;
}

.btn-third-pri {
  text-decoration: none;
  /* box-shadow: none !important; */
  background-color: #3d349d;
  font-weight: 700;
  border-radius: 5px;
  padding: 20px 40px !important;
  font-size: 14px !important;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  line-height: 1.5;
  position: relative;
  width: 100%;
  background-color: #ffffff;
  color: #3d349d !important;
  border-color: #fff;
  padding-right: 15px;
  padding-left: 15px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 8px;
}

.btn-third-pri > a {
  color: #3d349d;
  text-decoration: none;
}

.btn-third-pri:hover {
  background: #3d349d;
  border-color: #3d349d;
  color: #fff !important;
  box-shadow: 6px 12px 40px 0px#3d349d;
}
.btn-third-pri:hover > a {
  color: #fff;
}

/* sdfsdfsf */
.btn-third-sec {
  /* box-shadow: none !important; */
  background-color: #3d349d;
  font-weight: 700;
  border-radius: 5px;
  padding: 20px 40px !important;
  font-size: 14px !important;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  line-height: 1.5;
  position: relative;
  width: 100%;
  background-color: #3d349d;
  color: #fff !important;
  border-color: #3d349d;
  padding-right: 15px;
  padding-left: 15px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 8px;
  text-decoration: none;
}

.btn-third-sec > a {
  color: #3d349d;
  text-decoration: none;
}

.btn-third-sec:hover {
  background: #3d349d;
  box-shadow: 6px 12px 40px 0px#3d349d;
}
.btn-third-sec:hover > a {
  color: #fff;
}

.btn-third-pri {
  text-decoration: none;
  /* box-shadow: none !important; */
  background-color: #3d349d;
  font-weight: 700;
  border-radius: 5px;
  padding: 20px 40px !important;
  font-size: 14px !important;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  line-height: 1.5;
  position: relative;
  width: 100%;
  background-color: #ffffff;
  color: #3d349d !important;
  border-color: #fff;
  padding-right: 15px;
  padding-left: 15px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 8px;
}

.btn-third-pri > a {
  color: #3d349d;
  text-decoration: none;
}

.btn-third-pri:hover {
  background: #3d349d;
  border-color: #3d349d;
  color: #fff !important;
  box-shadow: 6px 12px 40px 0px#3d349d;
}
.btn-third-pri:hover > a {
  color: #fff;
}

/* sdfsdfsf */
.btn-third-sec1 {
  /* box-shadow: none !important; */
  background-color: #1d6a1f;
  font-weight: 700;
  border-radius: 5px;
  padding: 20px 40px !important;
  font-size: 14px !important;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  line-height: 1.5;
  position: relative;
  width: 100%;
  background-color: #1d6a1f;
  color: #fff !important;
  border-color: #1d6a1f;
  padding-right: 15px;
  padding-left: 15px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 8px;
  text-decoration: none;
}

.btn-third-sec1 > a {
  color: #1d6a1f;
  text-decoration: none;
}

.btn-third-sec1:hover {
  background: #1d6a1f;
  box-shadow: 6px 12px 40px 0px#1d6a1f;
}
.btn-third-sec1:hover > a {
  color: #fff;
}

.btn-third-pri1 {
  text-decoration: none;
  /* box-shadow: none !important; */
  background-color: #1d6a1f;
  font-weight: 700;
  border-radius: 5px;
  padding: 20px 40px !important;
  font-size: 14px !important;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  line-height: 1.5;
  position: relative;
  width: 100%;
  background-color: #ffffff;
  color: #1d6a1f !important;
  border-color: #fff;
  padding-right: 15px;
  padding-left: 15px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 8px;
}

.btn-third-pri1 > a {
  color: #1d6a1f;
  text-decoration: none;
}

.btn-third-pri1:hover {
  background: #1d6a1f;
  border-color: #1d6a1f;
  color: #fff !important;
  box-shadow: 6px 12px 40px 0px#1d6a1f;
}
.btn-third-pri1:hover > a {
  color: #fff;
}

/* sdfsdfsf */
.btn-third-sec2 {
  /* box-shadow: none !important; */
  background-color: #f52222;
  font-weight: 700;
  border-radius: 5px;
  padding: 20px 40px !important;
  font-size: 14px !important;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  line-height: 1.5;
  position: relative;
  width: 100%;
  background-color: #f52222;
  color: #fff !important;
  border-color: #f52222;
  padding-right: 15px;
  padding-left: 15px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 8px;
  text-decoration: none;
}

.btn-third-sec2 > a {
  color: #f52222;
  text-decoration: none;
}

.btn-third-sec2:hover {
  background: #f52222;
  box-shadow: 6px 12px 40px 0px#f52222;
}
.btn-third-sec2:hover > a {
  color: #fff;
}

.btn-third-pri2 {
  text-decoration: none;
  /* box-shadow: none !important; */
  background-color: #f52222;
  font-weight: 700;
  border-radius: 5px;
  padding: 20px 40px !important;
  font-size: 14px !important;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  line-height: 1.5;
  position: relative;
  width: 100%;
  background-color: #ffffff;
  color: #f52222 !important;
  border-color: #fff;
  padding-right: 15px;
  padding-left: 15px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 8px;
}

.btn-third-pri2 > a {
  color: #f52222;
  text-decoration: none;
}

.btn-third-pri2:hover {
  background: #f52222;
  border-color: #f52222;
  color: #fff !important;
  box-shadow: 6px 12px 40px 0px#f52222;
}
.btn-third-pri2:hover > a {
  color: #fff;
}

.cardthumb {
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 10px 10px 0 0;

  transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.cardthumb img {
  position: absolute;
  height: 106%;

  transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.cardthumb:hover img {
  transform: scale(1.1, 1.1);
}

.cardthumb:hover {
  transform: translateY(-10px);
}
