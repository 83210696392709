.footer {
  position: relative !important;
  padding: 80px 0;
  display: block;
  color: aliceblue;
  margin-top: -10px;
  background: #1c1659;
}

.footer li {
  list-style: none;
}
/* .footer::before {
  content: "";
  background-color: #1c1659;
  position: absolute;
  width: 95%;
  height: 100%;
  left: 0;
  top: 0;
  border-top-right-radius: 50px;
} */

.container {
  position: relative;
}
.footer-row {
  display: flex;
  justify-content: space-around;
}
.footercol {
  width: 20%;
}

.footerlink {
  color: white;
  text-decoration: none;
}

.footerimg {
  position: absolute;
  right: 0;
  bottom: 0;
}

.footerlogo {
  color: #fff;
}

.footerlink:hover {
  color: rgb(159, 90, 255);
}
