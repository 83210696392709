.bg-yellow {
  border-radius: 15px !important;
  /* background-color: #ffb039 !important; */
  background-color: #5659b8 !important;
  padding: 3rem !important;
  color: white;
  margin-bottom: 24px;
}

.bg-blue {
  border-radius: 15px !important;
  /* background-color: #20398d !important; */
  background-color: #44bd32 !important;
  padding: 3rem !important;
  color: white;
  margin-bottom: 24px;
}

.bg-pink {
  border-radius: 15px !important;
  /* background-color: #ff3d53 !important; */
  background-color: #c21616 !important;
  padding: 3rem !important;
  color: white;
  margin-bottom: 24px;
}

.newimage {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  object-fit: cover;
  top: 0;
}

.col-projects-left {
  display: flex;
  width: 35%;
  flex-direction: column;
  justify-content: space-between;
}

.col-projects-right {
  display: flex;

  flex-direction: column;
  justify-content: space-between;
}

.proj-image {
  align-self: flex-start;
  object-fit: contain;
}

.proj-row {
  display: flex;
  justify-content: space-between;
}

.proj-btn-group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
