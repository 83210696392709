.contactrow {
  display: flex;
  justify-content: space-between;
}

.contactcol-left {
  padding: 16px;
}

.contactcol-right {
  padding: 16px;
}

.bg-white {
  background: white;
  padding: 24px;
  box-shadow: 0px 30px 100px #0000001f;
}

input {
  border: none !important;
  background-color: #f6f6f6;
  color: #b7b7b7;
  text-align: center;
  height: 46px;
  font-weight: 900;
  font-size: 18px;
  padding: 16px;
  display: block;
  margin-bottom: 5px !important;
  color: #787878 !important;
}

textarea {
  border: none !important;
  background-color: #f6f6f6;
  color: #b7b7b7;
  text-align: center;
  height: 250px !important;
  font-weight: 900;
  font-size: 18px;
  resize: none;
  padding: 16px;
  display: block;
  margin-bottom: 5px !important;
  color: #787878 !important;
  width: 100%;
}

.contact-listitem {
  list-style: none;
}
