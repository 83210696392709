.newsection {
  position: relative !important;
}

.newsection::before {
  content: "";
  position: absolute;
  width: 93%;
  height: 90%;
  right: 0;
  top: 0;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
  background-color: #f9f9f9;
}

.newcontainer {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  /* flex-wrap: wrap; */
  margin-right: -15px;
  margin-left: -15px;
  justify-content: flex-start !important;
}

.jumbo-left {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  text-align: left !important;
  margin-bottom: auto !important;
  margin-top: auto !important;
  margin-left: 8.333333%;
}

.jumbo-right {
  position: relative;
}

.btn-group {
  display: flex;
  margin-top: 48px;
}

.btn {
  /* box-shadow: none !important; */
  background-color: #3d349d;
  font-weight: 700;
  border-radius: 5px;
  padding: 20px 40px !important;
  font-size: 14px !important;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  line-height: 1.5;
  position: relative;
  width: 100%;
  margin: 0 16px 0 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-main {
  background-color: #3d349d;
  color: #fff !important;
  padding-right: 15px;
  padding-left: 15px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-main > a {
  color: white;
  text-decoration: none;
}

.btn-secondary {
  background-color: transparent;
  color: #3d349d !important;
  border-color: #3d349d;
  padding-right: 15px;
  padding-left: 15px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-secondary > a {
  color: #3d349d;
  text-decoration: none;
}

h2 {
  font-family: "sans-bold", sans-serif;
  font-weight: 900;
  font-size: 50px;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  margin-top: 0;
  color: #000;
}

.btn:hover {
  box-shadow: 6px 12px 40px 0px#3d349d;
}

.btn-secondary:hover {
  background: #3d349d;
}
.btn-secondary:hover > a {
  color: #fff;
}
