* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-family: "sans-reguler", sans-serif;
  color: #787878;
  font-size: 16px;

  letter-spacing: 0.03em;
  line-height: 1.7em;
}
