.container {
  max-width: 1200px;
  margin: 0 auto;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.logo {
  display: flex;
  justify-self: flex-start;
}
.navitemgroup {
  display: flex;
  flex-direction: row;
  justify-self: flex-end;
  text-decoration: none;
  list-style: none;
  align-items: center;
}
.navitem {
  font-size: 14px;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  color: #000;
  font-weight: 700;
  font-family: "sans-bold", sans-serif;
}

.upper {
  text-transform: uppercase;
}

.navitem > a {
  color: #3d349d;
  text-decoration: none;
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.navitem:hover > a {
  color: #958ee1;
}
.github {
  display: flex;
  align-content: center;
  border-radius: 50px;
  background: #3d349d;
  padding: 5px 12px;
  margin-left: 8px;
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.github > a {
  color: white;
  text-decoration: none;
  margin-left: 5px;
}

.githubicon {
  color: white;
}

.github:hover {
  background: rgb(8, 140, 201);
  box-shadow: 5px 10px 10px rgba(8, 140, 201, 0.35);
}
