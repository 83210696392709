.services {
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
  background-color: #fff9f4 !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

h4 {
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  margin: 16px auto;
}

.card-group {
  display: flex;
  justify-content: space-between;
}

.section-heading {
  text-align: center;
  margin-bottom: 24px;
  width: 700px;
  margin: 48px auto;
}
